import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/device';

const deviceInit = {
	"deviceId": 0,
	"deviceName": '',
	"status": '',
	"statusId": 1,
	"buildingId": 0,
	"buildingAddress": '',
	"buildingCorpName": '',
	"salesforceBuildingId": '',
	"customerId": 0,
	"customerName": "",
	"salesforceCustomerId": "",
	"deviceTypeId": 0,
	"deviceTypeShort": "",
	"deviceTypeLong": "",
	"lastCat1InspDate": "",
	"lastPeriodicInspDate": "",
	"lastCat5InspDate": "",
	"cat5DueDate": "",
	"hydroStatusId": 0,
	"hydroStatus": "No",
	"codeYear": "",
	"controllerType": "",
	"ppnStatusId": 0, // used for DLM
	"ppnStatus": "No",
	"notes": "",
	"brakeComplianceId": 0,
	"brakeCompliance": "No",
	"manufacturer": "",
	"certificateExpiration": "",
	"roles": [],
	"offices": [],
	"aliasName": ''
};

export { deviceInit };

export const transferDevicesToBuilding = (data) => {
	let url = `${apiRoot}/TransferDevicesToBuilding`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const transferSingleDeviceToBuilding = (data, deviceId) => {

	let url = `${apiRoot}/TransferSingleDeviceToBuilding/${deviceId}`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};


export const search = (data) => {
	let url = `${apiRoot}/Search`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const getAllByCustomerId = (customerId) => {
	let url = `${apiRoot}/GetAllByCustomerId/${customerId}`;

	const options = {
		method: API_METHOD.POST
	};
	return handleFetch(url, options, true);
};

export const getAllByBuildingId = (buildingId) => {
	let url = `${apiRoot}/GetAllByBuildingId/${buildingId}`;

	const options = {
		method: API_METHOD.POST
	};
	return handleFetch(url, options, true);
};

export const getSingle = (id) => {

	let url = `${apiRoot}/GetSingle/${id}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};

export const update = (data) => {
	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const updateFromInspection = (data) => {
	let url = `${apiRoot}/UpdateFromInspection`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const verifyDevice = (deviceName) => {
	let url = `${apiRoot}/verifyDevice`;

	let payload = {
		deviceName: deviceName
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};
	return handleFetch(url, options, true);
};

// get building dropdown by address
export const getDropdownByDeviceName = () => {

	let url = `${apiRoot}/GetDropdownByDeviceName`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {

	}
};