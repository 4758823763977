import { ROLE_PERMISSION as ActionTypes } from '../actionTypes';

const initialState = {

    roles: [],
    permissionTypes: [],
    rolePermissions: []

};


export const rolePermissionReducer = (state = initialState, action) => {

    switch (action.type) {



        case ActionTypes.SET_ROLES:
            return {
                ...state,
                roles: action.payload,
            };

        case ActionTypes.SET_PERMISSIONS:
            return {
                ...state,
                permissionTypes: action.payload,
            };





        default:
            return state;
    };
};