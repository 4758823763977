// userApi.js is used to populate inspectors and other modules that need to access user information - inspections, admin module

import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/user';
const apiRootAuth = ENDPOINTS.API + '/auth';

const userInit = {
	userId: 0, // int userId from User table
	id: "", // user Guid from AspNetTable

	firstName: "",
	lastName: "",
	middleName: "",
	fullNameFirstLast: "",
	fullNameLastFirst: "",
	initials: "",

	phoneNumber: "", // 10 digit phone number
	homePhone: "", // 10 digit phone number
	mobilePhone: "",// 10 digit phone number

	dobNowEmail: "",
	email: "",
	userName: '',

	hasAccess: false,
	isActive: false,
	isAdmin: false,
	isDirector: false,
	isInspector: false,
	isOfficeStaff: false,
	isWitness: false,
	canCreateCrossStateInspections: false,
	newInspectionExperienceEnabled: false,

	underDirectorId: "",
	license: "",
	licenseExpiresOn: null,
	licenseTransferDate: null,


	label: "", // last, first for Dropdown
	value: "", // user guid
	createdBy: "", // guid
	dateCreated: null,
	dateModified: null,
	modifiedBy: "", // guid

	salesforceContactId: "",
	salesforceUserId: "",

	avatar: "",
	offices: [],
	roles: []
};

export { userInit };

export const getActiveInspectors = () => {

	let url = `${apiRoot}/GetActiveInspectors`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};



// will return the AspNetUser domain model - not the repo
export const getAllUsers = () => {

	let url = `${apiRoot}/GetAll`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


// takes a guid
export const getSingleUser = (userId) => {

	let url = `${apiRoot}/GetSingle/${userId}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};



// take an int - we are getting a legacy user from the user table
export const getSingleUserLegacy = (userId) => {

	let url = `${apiRoot}/GetSingleLegacy/${userId}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};



// calling consolidated Upsert method to add or insert user
// into aspnet table as well as legacy user table
export const upsertUser = (payload) => {

	let url = `${apiRoot}/ConsolidateAddNewUser`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


// NEW user update - limited view model - no roles, no password
export const updateUser = (item) => {

	let payload = {
		id: item.id,
		userId: item.userId,
		firstName: item.firstName,
		lastName: item.lastName,
		email: item.email, // non edit
		middleName: item.middleName,
		phoneNumber: item.phoneNumber,
		homePhone: item.homePhone,
		mobilePhone: item.mobilePhone,
		isActive: item.isActive,
		isInspector: item.isInspector,
		hasAccess: item.hasAccess,
		isAdmin: item.isAdmin,
		isDirector: item.isDirector,
		isOfficeStaff: item.isOfficeStaff,
		isWitness: item.isWitness,
		canCreateCrossStateInspections: item.canCreateCrossStateInspections,
		newInspectionExperienceEnabled: item.newInspectionExperienceEnabled,
		dobNowEmail: item.dobNowEmail,
		licenseExpiresOn: item.licenseExpiresOn,
		licenseTransferDate: item.licenseTransferDate,
		license: item.license,
		underDirectorId: item.underDirectorId === "NULL" ? "" : item.underDirectorId, // convert to empty string and pass to backend - it will be null and all good
	}

	// only updating limited fields for the user
	let url = `${apiRoot}/UpdateUser`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};

export const insertUser = (payload) => {

	// only updating limited fields for the user
	let url = `${apiRoot}/InsertUser`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


export const passwordReset = (payload) => {

	let url = `${apiRootAuth}/AdminPasswordReset`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		throw e;
	}
};

export const updateEmail = (payload) => {

	let url = `${apiRoot}/UpdateEmailAddress`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		throw e;
	}
};

export const updateUserName = (payload) => {

	let url = `${apiRoot}/UpdateUserName`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		throw e;
	}
};


export const getAllRoles = () => {

	let url = `${apiRoot}/GetAllRoles`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};



export const updateRoles = (payload) => {

	let url = `${apiRoot}/UpdateUserRoles`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};


export const getUsersByRole = (roleId) => {

	let url = `${apiRoot}/GetUsersByRole/${roleId}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};






