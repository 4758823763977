import { USER as ActionTypes } from '../actionTypes';
import { userInit } from '../../services/userApi';

const initialState = {

    user: userInit,
    users: [],
    userCounts: {
        active: 0,
        inactive: 0,
        all: 0
    },
    filteredUserList: [],
    filterOptions: {
        topLevel: 'ALL', // all | active | inactive, roles
        roles: [], // addition to top level
        search: '', // addition to search

    }
};


export const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_USER:

            return {
                ...state,
                user: action.payload
            };
        case ActionTypes.CLEAR_USER:

            return {
                ...state,
                user: initialState
            };

        case ActionTypes.SET_USERS:

            return {
                ...state,
                users: action.payload.users,
                filteredUserList: action.payload.users,
                userCounts: action.payload.userCounts
            };

        case ActionTypes.SET_USER_FILTERS:

            return {
                ...state,
                filteredUserList: action.payload.filteredUserList,
                filterOptions: {
                    topLevel: action.payload.filterOptions.topLevel,
                    roles: action.payload.filterOptions.roles,
                    search: action.payload.filterOptions.search
                }
            };
        default:
            return state;
    };
};