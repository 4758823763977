import { SERVICE_JOB as ActionTypes } from '../actionTypes';


const initialState = {
    serviceJob: {
        id: 0,
        buildingId: 0,
        customerId: 0,
        status: '',
        StatusId: 0,
        year: '',
        poNumber:0,
        startDate: '',
        endDate: '',
        // elevatorCompanyId: 0,
        // testingCompanyId: 0,
        customer: {
            customerId: 0,
            customerName: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phoneFormatted: '',
            faxFormatted: '',
            poRequired: false,
            email: '',
            emailCC: '',
            complianceContact: '',
            salesforceId: ''
        },
        building: {
            agentId: 0,
            activeDevices: 0,
            address: '',
            buildingName: '',
            block: '',
            lot: '',
            coiReceived: 0,
            borough: '',
            zip: '',
            hood: '',
            taxExempt: 0,
            coiExpDate: '',
            salesforceId: '',
            superName: '',
            superEmail: '',
            superPhoneFormatted: ''
        },
        serviceJobLines: []
    },
  
};



export const serviceJobReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_SERVICE_JOB:

            return {
                ...state,
                serviceJob: action.payload
            };

        case ActionTypes.CLEAR_SERVICE_JOB:

            return {
                ...state,
                serviceJob: initialState.serviceJob
            };
            
        case ActionTypes.UPDATE_SERVICE_JOB:
            return {
                ...state,
                serviceJob: action.payload
            }
           
        case ActionTypes.UPDATE_SERVICE_JOB_STATUS:
            return {
                ...state,
                serviceJob: {
                    ...state.serviceJob,
                    statusId: action.payload.newStatusId,
                },
            };
        case ActionTypes.UPDATE_SERVICE_JOB_DATES:
                return {
                    ...state,
                    serviceJob: {
                        ...state.serviceJob,
                        startDate: action.payload.startDate,
                        endDate: action.payload.endDate,
                    },
                };

        default:
            return state;
    };
};